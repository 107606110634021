<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">工资包</span>
        <img src="../../assets/right.png" alt />
        <span class="center">我的工资包</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资包详情</span>
      </div>
    </div>
    <el-card>
      <div>
        <el-card class="box-card">
          <div class="left_top">
            <div class="left_icon">
              <span></span>
              <span>工资单</span>
            </div>
          </div>
          <el-descriptions class="margin-top" style="margin-top:" :column="3" :size="size">
            <el-descriptions-item label="年月">{{ detailInfo.date }}</el-descriptions-item>
            <el-descriptions-item label="工资单名称">{{ detailInfo.payName }}</el-descriptions-item>
            <el-descriptions-item label="所属项目">{{ detailInfo.name }}</el-descriptions-item>
          </el-descriptions>
        </el-card>
      </div>

      <el-card class="box-card">
        <div class="left_top" style="margin-bottom:20px">
          <div class="left_icon">
            <span></span>
            <span>工资单详情</span>
          </div>
          <span slot="right">
            <el-button type="text" @click="exportExcel">下载验收表</el-button>
            <el-upload
              v-if="activState === '4'"
              style="display: inline-block; margin-left: 10px"
              action
              :multiple="false"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :on-change="importExcel"
              :file-list="uploadFiles"
            >
              <el-button type="text" :disabled="dispatchList.length === 0">上传验收表</el-button>
            </el-upload>
          </span>
        </div>

        <el-table :data="dispatchList" style="width: 100%">
          <el-table-column label="姓名" prop="userName">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="身份证号" prop="idCardNumber" width="190"></el-table-column>
          <el-table-column label="银行卡号" prop="accountCode"></el-table-column>

          <el-table-column label="基本工资" prop="basePay">
            <template slot-scope="scope">{{ scope.row.basePay }}</template>
          </el-table-column>
          <el-table-column label="加班工资" prop="overtimePay" width="200">
            <template slot-scope="scope">{{ scope.row.overtimePay }}</template>
          </el-table-column>
          <el-table-column label="养老" prop="amount">
            <template slot-scope="scope">{{ scope.row.oldPension | money }}</template>
          </el-table-column>
          <el-table-column label="医疗" prop="medicalCare">
            <template slot-scope="scope">{{ scope.row.medicalCare | money }}</template>
          </el-table-column>
          <el-table-column label="工伤" prop="Injury">
            <template slot-scope="scope">{{ scope.row.Injury | money }}</template>
          </el-table-column>
          <el-table-column label="失业" prop="Unemployment">
            <template slot-scope="scope">{{ scope.row.Unemployment | money }}</template>
          </el-table-column>
          <el-table-column label="生育" prop="birth">
            <template slot-scope="scope">{{ scope.row.birth | money }}</template>
          </el-table-column>
          <el-table-column label="扣款" prop="Deduction">
            <template slot-scope="scope">{{ scope.row.Deduction | money }}</template>
          </el-table-column>
          <el-table-column label="实发工资" prop="netSalary">
            <template slot-scope="scope">{{ scope.row.netSalary | money }}</template>
          </el-table-column>
          <el-table-column label="总工资" prop="outerTrade_amount">
            <template slot-scope="scope">{{ scope.row.outerTrade_amount | money }}</template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import moment from 'moment';
import XLSX from 'xlsx';

import { parseJsonToTable } from '../../lib/dev';
import { api } from '../../api/base/index';


// 结算


export const registrationStateEdit = api('/registration')(
  'registration.state.edit.json'
);
export const taskCheckList = api('/taskCheck')('taskCheck.list.json');
export const ContractSignerUrl = api('/contract')('contract.signer.url.json');

export default {
  components: {

  },
  data () {
    return {
      size: '',
      code: '',
      detailInfo: {
        date: '2021-10',
        name: '中国炼油厂',
        payName: '基础工资',
      },
      dispatchList: [{
        Deduction: '200',
        Injury: '50',
        Unemployment: '100',
        accountCode: '123456789987654321',
        basePay: '10000',
        birth: '100',
        idCardNumber: '610623199803041000',
        medicalCare: '50',
        name: '赵三',
        netSalary: '11900',
        oldPension: '100',
        outerTrade_amount: '12500',
        overtimePay: '2500',
      }
      ],
    };
  },
  created () {
    this.code = this.$route.query.code;


    // this.getCheckList();
  },
  methods: {
    // 获取验收单列表
    async getCheckList () {
      const res = await taskCheckList({
        taskCode: this.code,
        $ignoreRepeat: true
      });
      this.checkList = res.list.map(it => {
        return {
          ...it,
          loading: false
        };
      });
    },

    goBack () {
      window.history.go(-1);
    },
    exportExcel () {
      if (this.multipleSelection.length === 0) {
        return this.$message.error('请先选择要导出的数据！');
      }
      const time = moment().format('YYMMDDHH');
      const data = this.multipleSelection;
      parseJsonToTable(
        `验收名单_${time}`,
        {
          编号: 'code',
          姓名: 'userName',
          手机号: 'userPhone',
          身份证号: 'userIdCardNumber',
          实发金额: 'amount',
          完成度: 'complateRate',
          完成度备注: 'complateRemark'
        },
        data,
        true
      );
    },

    file2Xce (file) {
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary'
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.edit_top {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding: 20px 5px;
  width: 100%;
  .border_left {
    width: 48%;
    // flex-direction: column;
    // display: flex;
    .left_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .left_icon {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          padding: 8px 4px;
          background: #4977fc;
          margin-right: 10px;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .left-demo {
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 20px 10px;
      border-bottom: 1px dashed #000;
      font-size: 15px;
      .left-demo_left {
        display: flex;
        align-items: center;
        img {
          width: 39px;
          height: 35px;
        }
        span {
          margin-left: 15px;
        }
      }
      .left-demo_right {
        color: #b4b4b4;
      }
    }
    .left_buttom {
      margin: 140px 0 0 0;
      height: 100%;
      flex: 1;
    }
    .left-demo:last-child {
      border: none;
    }
  }
  .border_right {
    width: 48%;
    display: flex;
    flex-wrap: wrap;

    border-radius: 10px;

    .right_one {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      .left_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .left_icon {
          display: flex;
          align-items: center;
          span:nth-child(1) {
            padding: 8px 4px;
            background: #4977fc;
            margin-right: 10px;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .left-demo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        color: #b4b4b4;
        font-size: 15px;
        border-bottom: 1px dashed #000;
        .left-demo_left {
          display: flex;
          align-items: center;
          color: #000;
          img {
            width: 20px;
            height: 23px;
          }
          span {
            margin-left: 15px;
          }
        }
      }
      .left-demo:last-child {
        border-bottom: none;
      }
    }
    .right_one:nth-child(2) {
      margin: 20px 0 0 0;
      .left-demo {
        border-bottom: 1px dashed #000;
      }
      .left-demo:last-child {
        border: none;
      }
    }
  }
}
.basic-info {
  margin-bottom: 100px;
}
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}

.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
</style>
